export default {
  AWS_REGION: process.env.REACT_APP_AWS_REGION || "",
  AWS_COGNITO_IDENTITY_POOL_ID:
    process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID || "",
  AWS_COGNITO_USER_POOL_ID:
    process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID || "",
  AWS_COGNITO_CLIENT_ID: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID || "",
  AWS_COGNITO_LOGIN: process.env.REACT_APP_AWS_COGNITO_LOGINS || "",
  API_GATEWAY_URL: process.env.REACT_APP_API_GATEWAY_URL || "",
  EVERCLEAN_APP_URL: process.env.REACT_APP_EVERCLEAN_APP_URL || "",
  PLATFORM_VERSION: process.env.REACT_APP_PLATFORM_VERSION || "",
  GOOGLE_MAPS_API_KEY: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "",
  HELPHERO_APP_ID: process.env.REACT_APP_HELPHERO_APP_ID || "",
  USER_MONITORING_ENABLED:
    process.env.REACT_APP_USER_MONITORING_ENABLED === "true",
  AWS_RUM_SESSION_SAMPLE_RATE: process.env.REACT_APP_AWS_RUM_SESSION_SAMPLE_RATE
    ? parseInt(process.env.REACT_APP_AWS_RUM_SESSION_SAMPLE_RATE, 10)
    : undefined,
  AWS_RUM_GUEST_ROLE_ARN: process.env.REACT_APP_AWS_RUM_GUEST_ROLE_ARN,
  AWS_RUM_IDENTITY_POOL_ID: process.env.REACT_APP_AWS_RUM_IDENTITY_POOL_ID,
  AWS_RUM_ENDPOINT: process.env.REACT_APP_AWS_RUM_ENDPOINT,
  AWS_RUM_TELEMETRIES: process.env.REACT_APP_AWS_RUM_TELEMETRIES
    ? process.env.REACT_APP_AWS_RUM_TELEMETRIES.split(",").map((_) => _.trim())
    : undefined,
  AWS_RUM_ALLOW_COOKIES: process.env.REACT_APP_AWS_RUM_ALLOW_COOKIES === "true",
  AWS_RUM_ENABLE_XRAY: process.env.REACT_APP_HELPHERO_APP_ID === "true",
  AWS_RUM_APPLICATION_ID: process.env.REACT_APP_AWS_RUM_APPLICATION_ID,
  AWS_RUM_APPLICATION_VERSION:
    process.env.REACT_APP_AWS_RUM_APPLICATION_VERSION,
  AWS_RUM_APPLICATION_REGION: process.env.REACT_APP_AWS_RUM_APPLICATION_REGION,
  AWS_RUM_RECORD_RESOURCE_URL:
    process.env.REACT_APP_AWS_RUM_RECORD_RESOURCE_URL === "true",
  DEVICES_TO_FETCH: Number(process.env.REACT_APP_DEVICES_TO_FETCH),
  DEVICES_PAGE_SIZE: Number(process.env.REACT_APP_DEVICES_PAGE_SIZE),
  ASSETS_TO_FETCH: Number(process.env.REACT_APP_ASSETS_TO_FETCH),
  ASSETS_PAGE_SIZE: Number(process.env.REACT_APP_ASSETS_PAGE_SIZE),
  MAINTENANCE_ASSETS_PAGE_SIZE: Number(
    process.env.REACT_APP_MAINTENANCE_ASSETS_PAGE_SIZE
  ),
  CONSUMABLE_PAGE_SIZE: Number(process.env.REACT_APP_CONSUMABLE_PAGE_SIZE),
  OVERVIEW_DISCARD_ASSET_IDS:
    process.env.REACT_APP_OVERVIEW_DISCARD_ASSET_IDS?.split(",").map((_) =>
      Number(_)
    ),
  DROOP_TO_USD: Number(process.env.REACT_APP_DROOP_TO_USD),
  DROOP_TO_CHF: Number(process.env.REACT_APP_DROOP_TO_CHF),
  DROOP_TO_EUR: Number(process.env.REACT_APP_DROOP_TO_EUR),
  DROOP_TO_GBP: Number(process.env.REACT_APP_DROOP_TO_GBP),
};
