import axios from "axios";
import EnvVars from "../helpers/EnvVars";
import { RealUser, User } from "./Interfaces";
import AuthService from "./AuthService";
import { DroopleCache } from "../helpers/DroopleCache";
import { v4 as uuidv4 } from "uuid";

export type ResourceName =
  | "devices"
  | "assets"
  | "clients"
  | "users"
  | "telemetry"
  | "consumables"
  | "sensors"
  | "models"
  | "gateways"
  | string;
axios.defaults.baseURL = EnvVars.API_GATEWAY_URL;

export class ErrorWithCode extends Error {
  code?: string | number;
  constructor(message: string | undefined, code?: string | number) {
    super(message);
    this.code = code;
  }
}
export default class DBService {
  static isUserAnonymous(user: User): boolean {
    return !!(
      !user.id ||
      user.email.toLowerCase() === "droople.demo@gmail.com" ||
      user.email.toLowerCase() === "droople.demo+filtration@gmail.com"
    );
  }

  static forbidAnonymousUser(user: User) {
    if (DBService.isUserAnonymous(user)) {
      throw new Error("Operation forbidden to anonymous users.");
    }
  }

  static async get<T>(
    resourceName: ResourceName,
    ...path: (string | number)[]
  ) {
    await AuthService.refreshTokenIfNeeded();
    return axios
      .get<{ code?: string; message?: string; data?: T }>(
        "/" + resourceName + (path.length > 0 ? "/" + path.join("/") : ""),
        {
          headers: {
            Authorization: `Bearer ${AuthService.getIdToken()}`,
          },
        }
      )
      .then((res) => {
        if (res.data.code) {
          const err = new ErrorWithCode(res.data.message, res.data.code);
          console.error(`Error #${res.data.code}: ${res.data.message}`);
          throw err;
        }
        return res.data;
      });
  }

  static getData<T>(
    resourceName: ResourceName,
    ...path: (string | number)[]
  ): Promise<T | undefined> {
    return DBService.get<T>(resourceName, ...path).then((_) => _.data);
  }

  static async getWithParams<T>(
    params: object,
    resourceName: ResourceName,
    ...path: (string | number)[]
  ) {
    await AuthService.refreshTokenIfNeeded();
    return axios
      .get<{ code?: string; message?: string; data?: T; meta?: any }>(
        "/" + resourceName + (path.length > 0 ? "/" + path.join("/") : ""),
        {
          headers: {
            Authorization: `Bearer ${AuthService.getIdToken()}`,
          },
          params: params,
        }
      )
      .then((res) => {
        if (res.data.code) {
          const err = new ErrorWithCode(res.data.message, res.data.code);
          console.error(`Error #${res.data.code}: ${res.data.message}`);
          throw err;
        }
        return res.data;
      });
  }
  static getDataWithParams<T>(
    params: object,
    resourceName: ResourceName,
    ...path: (string | number)[]
  ) {
    return DBService.getWithParams<T>(params, resourceName, ...path).then(
      (_) => _.data
    );
  }
  static getDataWithParamsAndRetries<T>(
    retries: number,
    params: object,
    resourceName: ResourceName,
    ...path: (string | number)[]
  ): Promise<T | undefined> {
    return DBService.getWithParams<T>(params, resourceName, ...path)
      .then((_) => _.data)
      .catch((err) => {
        if (retries > 0) {
          console.warn(err);
          console.warn("Retrying...");
          return DBService.getDataWithParamsAndRetries<T>(
            retries - 1,
            params,
            resourceName,
            ...path
          );
        } else {
          throw err;
        }
      });
  }

  static getUserByEmail(email: string) {
    return DBService.getWithParams<RealUser>({ email }, "users");
  }

  static async update<T>(
    data: T,
    resourceName: ResourceName,
    ...path: (string | number)[]
  ) {
    await AuthService.refreshTokenIfNeeded();
    DBService.forbidAnonymousUser(await AuthService.getProfile());
    DroopleCache.invalidate();
    return axios
      .put<{ code?: string; message?: string; data?: T }>(
        "/" + resourceName + (path.length > 0 ? "/" + path.join("/") : ""),
        data,
        {
          headers: {
            Authorization: `Bearer ${AuthService.getIdToken()}`,
          },
        }
      )
      .then((res) => {
        if (res.data.code) {
          const err = new ErrorWithCode(res.data.message, res.data.code);
          console.error(`Error #${res.data.code}: ${res.data.message}`);
          throw err;
        }
        return res.data;
      });
  }

  static async patch<T>(
    data: Partial<T>,
    resourceName: ResourceName,
    ...path: (string | number)[]
  ) {
    await AuthService.refreshTokenIfNeeded();
    DBService.forbidAnonymousUser(await AuthService.getProfile());
    DroopleCache.invalidate();
    return axios
      .patch<{ code?: string; message?: string }>(
        "/" + resourceName + (path.length > 0 ? "/" + path.join("/") : ""),
        data,
        {
          headers: {
            Authorization: `Bearer ${AuthService.getIdToken()}`,
          },
        }
      )
      .then((res) => {
        if (res.data.code) {
          const err = new ErrorWithCode(res.data.message, res.data.code);
          console.error(`Error #${res.data.code}: ${res.data.message}`);
          throw err;
        }
        return res.data;
      });
  }

  static async post<T, R = T>(
    data: T,
    resourceName: ResourceName,
    ...path: (string | number)[]
  ): Promise<R> {
    await AuthService.refreshTokenIfNeeded();
    DBService.forbidAnonymousUser(await AuthService.getProfile());
    return DBService.postAllowAnonymous(data, resourceName, ...path);
  }

  static async delete(
    resourceName: ResourceName,
    ...path: (string | number)[]
  ) {
    await AuthService.refreshTokenIfNeeded();
    DBService.forbidAnonymousUser(await AuthService.getProfile());
    DroopleCache.invalidate();
    const res = await axios.delete(
      "/" + resourceName + (path.length > 0 ? "/" + path.join("/") : ""),
      {
        headers: {
          Authorization: `Bearer ${AuthService.getIdToken()}`,
        },
      }
    );
    if (res.data.code) {
      const err = new ErrorWithCode(res.data.message, res.data.code);
      console.error(`Error #${res.data.code}: ${res.data.message}`);
      throw err;
    }
  }

  static async postAllowAnonymous<T, R = T>(
    data: T,
    resourceName: ResourceName,
    ...path: (string | number)[]
  ): Promise<R> {
    DroopleCache.invalidate();
    return axios
      .post<{ code?: string; message?: string; data: R }>(
        "/" + resourceName + (path.length > 0 ? "/" + path.join("/") : ""),
        data,
        {
          headers: {
            Authorization: `Bearer ${AuthService.getIdToken()}`,
          },
        }
      )
      .then((res) => {
        if (res.data.code) {
          const err = new ErrorWithCode(res.data.message, res.data.code);
          console.error(`Error #${res.data.code}: ${res.data.message}`);
          throw err;
        }
        return res.data.data;
      });
  }

  static async uploadFileToFileUploadService(
    file: Blob,
    contentType: string
  ): Promise<string> {
    // get signed url to upload to s3 via put
    const what = uuidv4();
    const res = await axios.post<{
      code?: number;
      message?: string;
      data?: { s3PutObjectUrl: string; key: string };
    }>(
      "/upload_service",
      {
        key: what,
        contentType,
      },
      {
        headers: {
          Authorization: `Bearer ${AuthService.getIdToken()}`,
        },
      }
    );
    if (res.data.code !== 0) {
      throw new Error(res.data.message);
    }
    // upload blob to s3
    const response = await fetch(res.data.data!.s3PutObjectUrl, {
      method: "PUT",
      body: file,
    });
    if (!response.ok) {
      throw new Error(
        `Failed to upload the ${what} image. Cause: ${await response.json()}`
      );
    }

    return `https://droople-user-uploads.s3.eu-west-1.amazonaws.com/${
      res.data.data!.key
    }`;
  }

  static async deleteFileFromFileUploadService(
    resourceUrl: string
  ): Promise<undefined> {
    const splitted = resourceUrl.split("/");
    const name = splitted[splitted.length - 1];

    const res = await axios.delete<{
      code?: number;
      message?: string;
      data?: { s3DeleteObjectUrl: string };
    }>(`/upload_service/${name}`, {
      headers: {
        Authorization: `Bearer ${AuthService.getIdToken()}`,
      },
    });
    if (res.data.code !== 0) {
      throw new Error(res.data.message);
    }
    // delete object in s3
    const response = await fetch(res.data.data!.s3DeleteObjectUrl, {
      method: "delete",
    });
    if (!response.ok) {
      throw new Error(
        `Failed to delete the ${resourceUrl} image. Cause: ${await response.json()}`
      );
    }
    return undefined;
  }
}
